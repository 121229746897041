import React, { useState } from 'react';
import { Menu } from '../../menu';
import { H1 } from '@steinar-hovland/shx';

import Logo from '../logo/logo';
import { NavLink } from 'react-router-dom';
import './header.scss';

const Header = () => {
    const [isMenuVisible, setMenuVisiblity] = useState(false);

    const closeMenu = () => setMenuVisiblity(false);
    const toggleMenu = () => setMenuVisiblity(!isMenuVisible);

    return (
        <>
            <a
                href="#maincontent"
                className="sr-only focus:not-sr-only jump-to-content">
                Hopp til hovedinnhold
            </a>
            <header
                className={`app__header ${
                    !isMenuVisible ? 'show-menu' : 'hide-menu'
                }`}>
                <H1>
                    <NavLink
                        className="app__logo app__header-logo"
                        to="/prosjekter/"
                        title="Gå til prosjektoversikt">
                        <Logo />
                    </NavLink>
                </H1>
                <button
                    className="app__header__toogle-menu"
                    onClick={toggleMenu}
                    type="button">
                    <MenuIcon />
                    <span className="menu-label">Menu</span>
                </button>
                <nav className="app__navigation">
                    {Menu.map((menuItem) => {
                        const { label, path, icon = false } = menuItem;

                        return !icon ? (
                            <NavLink
                                key={path}
                                activeClassName="app__navigation__link--active"
                                onClick={closeMenu}
                                className="app__navigation__link"
                                to={path}>
                                {label}
                            </NavLink>
                        ) : (
                            <a
                                key={path}
                                className="app__navigation__social-link"
                                href={path}
                                aria-label={label}
                                rel="nofollow">
                                {icon}
                            </a>
                        );
                    })}
                </nav>
            </header>
        </>
    );
};

export default Header;

const MenuIcon = () => {
    return (
        <div className="menu-icon">
            <span className="menu-line" />
            <span className="menu-line" />
            <span className="menu-line" />
            <span className="menu-line" />
        </div>
    );
};
