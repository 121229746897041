import React from 'react';
import Logo from '../logo/logo';
import { Link } from 'react-router-dom';
import { ReactComponent as InstagramLogo } from '../../assets/icons/instagram-brands.svg';
import { ReactComponent as LinkedinLogo } from '../../assets/icons/linkedin-brands.svg';
import { ReactComponent as GithubLogo } from '../../assets/icons/github-brands.svg';
import './footer.scss';

const socialMediaAccounts = [
    {
        icon: GithubLogo,
        name: 'Github',
        link: 'https://github.com/steinarhovland'
    },
    {
        icon: LinkedinLogo,
        name: 'Linkedin',
        link: 'https://www.linkedin.com/in/steinarhovland/'
    },
    {
        icon: InstagramLogo,
        name: 'Instagram',
        link: 'https://www.instagram.com/steinar_hovland/'
    }
];

const Footer = () => {
    return (
        <footer className="app__footer">
            <Logo inverted />
            {socialMediaAccounts.map((account) => {
                return (
                    <a
                        className="app__footer__social-icon"
                        href={account.link}
                        key={account.name}
                        rel="nofollow noopener noreferrer"
                        target="_blank"
                        aria-label={`Open my ${account.name} profile in a new tab`}>
                        <account.icon className="footer__social-account" />
                    </a>
                );
            })}
            <nav className="app__footer__policies-list">
                <Link
                    className="app__footer__policies-list__link"
                    to="/privacy-policy">
                    Privacy policy
                </Link>
                <Link
                    className="app__footer__policies-list__link"
                    to="/cookies">
                    Cookies
                </Link>
            </nav>
        </footer>
    );
};

export default Footer;
