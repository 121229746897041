import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import './App.scss';

import Header from './components/header';
import Footer from './components/footer';

import Portfolio from './pages/Portfolio/Loadable';
import CaseStudy from './pages/CaseStudy/Loadable';

import About from './pages/About/Loadable';

import GetInTouch from './pages/GetInTouch/Loadable';
import ThankYou from './pages/ThankYou/Loadable';
import PrivacyPolicy from './pages/PrivacyPolicy/Loadable';
import Cookies from './pages/Cookies/Loadable';

import NotFoundPage from './pages/NotFoundPage/Loadable';

const App = () => {
    return (
        <BrowserRouter>
            <Header />
            <main id="maincontent">
                <Route component={onRouteChange} />
                <Switch>
                    <Route
                        exact
                        path="/"
                        component={() => <Redirect to="/prosjekter/" />}
                    />
                    <Route exact path="/prosjekter/" component={Portfolio} />
                    <Route exact path="/prosjekter/:id" component={CaseStudy} />
                    <Route exact path="/om-meg/" component={About} />
                    <Route exact path="/kontakt/" component={GetInTouch} />
                    <Route exact path="/takk/" component={ThankYou} />
                    <Route
                        exact
                        path="/privacy-policy/"
                        component={PrivacyPolicy}
                    />
                    <Route exact path="/cookies/" component={Cookies} />
                    <Route exact path="/whoopsie/" component={NotFoundPage} />
                    <Route
                        exact
                        path="/admin"
                        component={() =>
                            (window.location = 'http://localhost:3333')
                        }
                    />
                    <Route component={NotFoundPage} />
                </Switch>
            </main>
            <Footer />
        </BrowserRouter>
    );
};

const onRouteChange = () => {
    // TODO: Store scroll position for frontpage and reload same position on back.
    window.scrollTo(0, 0);
    return null;
};

export default App;
